import { Transaction } from "./types";

export function getAmount(transaction: Transaction) {
    return parseFloat(transaction[8].replace(",", "."));
}

export function getLabel(transaction: Transaction) {
    return transaction[11] ? transaction[11] : transaction[12];
}

export function getText(transaction: Transaction) {
    return transaction[5] + transaction[4]
}

export function bigger(a:string, b:string) { return a > b ? 1 : 0}

function editDistance(s1:string, s2:string) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();
  
    var costs = [];
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i === 0)
          costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) !== s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue),
                costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0)
        costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }
  
export function calcSimilarity(s1:string, s2:string) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / longerLength;
  }
  
  
export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  